import React from "react"
import Seo from "../seo"
import Form from "../Form"
import Layout from "../Layout"
import Heading from "../Heading"
import styled from "styled-components"
import BaseColumn from "../MainColumn"
import http from "../../utils/httpService"
import processData from "../../utils/processData"
import { parse } from "querystring"

const MainColumn = styled(BaseColumn)`
  margin: 6.4rem auto;
`

const formData = [
  {
    title: "Informações da compra",
    content: [
      {
        key: "combo",
        required: true,
        title: "Combo selecionado",
        options: [
          { label: "Alimentação Básica", value: 1 },
          { label: "Limpeza & Higiene", value: 2 },
          { label: "Doces & Guloseimas", value: 3 }
        ]
      },
      { key: "referrer", title: "Número de indicação" }
    ]
  },
  {
    title: "Informações pessoais",
    content: [
      { key: "name", title: "Nome Completo", required: true },
      { key: "email", type: "email", title: "E-mail", required: true },
      { key: "address", title: "Endereço", required: true },
      { key: "number", title: "Número", required: true, width: 40 },
      { key: "complement", title: "Complemento", width: 55 },
      { key: "district", title: "Bairro", required: true },
      { key: "city", title: "Cidade", required: true, width: 60 },
      { key: "cep", title: "CEP", required: true, width: 35 },
      { key: "ddd", title: "DDD", required: true, width: 15 },
      { key: "phone", type: "phone", title: "Telefone", required: true, width: 80 }
    ]
  },
  {
    title: "Informações para ganhos",
    collapsable: true,
    content: [
      {
        key: "accountType",
        title: "Tipo de conta",
        options: [
          { label: "Conta corrente", value: "Conta corrente" },
          { label: "Conta poupança", value: "Conta poupança" }
        ]
      },
      { key: "bank", title: "Banco" },
      { key: "account", title: "Conta", width: 65 },
      { key: "agency", title: "Agência", width: 30 },
      { key: "accountName", title: "Nome da conta", width: 60 },
      { key: "accountCPF", title: "CPF da conta", width: 35 }
    ]
  }
]

function Checkout({ location }) {
  const getCurrentCombo = () => {
    const value = location.search.slice(1)
    return Number(parse(value).order) || undefined
  }

  const handleSubmit = values => {
    const combos = ["Alimentação Básica", "Limpeza & Higiene", "Doces & Guloseimas"]
    const combo = combos[values.combo - 1]
    const content = processData({ ...values, combo }, formData)
    const data = { from: values.email, content, subject: `Nova compra: ${combo}` }

    return http.post("/send-email", { data })
  }

  return (
    <Layout>
      <Seo title="Comprar" />
      <MainColumn>
        <Heading
          title="Comprar"
          description="Preencha seu cadastro para dar continuação a sua compra"
        />
        <Form handleSubmit={handleSubmit} render={formData} defaultValues={{ combo: getCurrentCombo() }} />
      </MainColumn>
    </Layout>
  )
}

export default Checkout
